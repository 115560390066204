<template>
  <div>
    <FiltriSocieta @getSocieta="searchSocieta" @resetFilters="resetFilters" />
    <TableSociety @getSocieta="getSocieta" @resetFilters="resetFilters" />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  watch,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TableSociety from "@/components/components-fit/società/TableSocieta.vue";
import FiltriSocieta from "@/components/components-fit/società/FiltriSocieta.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "society",
  components: {
    TableSociety,
    FiltriSocieta,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Società", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const cod_affiliazione = computed(() => store.getters.cod_affiliazione);
    const denominazioneSocieta = computed(
      () => store.getters.denominazioneSocieta
    );
    const comitato = computed(() => store.getters.comitato);
    const rowsToSkipSocieta = computed(() => store.getters.rowsToSkipSocieta);
    const fetchRowsSocieta = computed(() => store.getters.fetchRowsSocieta);
    const sortColumnSocieta = computed(() => store.getters.sortColumnSocieta);
    const sortOrderSocieta = computed(() => store.getters.sortOrderSocieta);
    const tutteStagioniSocieta = computed(
      () => store.getters.tutteStagioniSocieta
    );
    const stagioneSocieta = computed(() => store.getters.defaultStagione);
    const stagioneCorrenteSocieta = computed(
      () => store.getters.stagioneCorrenteSocieta
    );
    const disciplina = computed(() => store.getters.disciplina);
    const affiliazioneDal = computed(() => store.getters.affiliazioneDal);
    const affiliazioneAl = computed(() => store.getters.affiliazioneAl);
    const regione = computed(() => store.getters.regione);
    const provincia = computed(() => store.getters.provincia);
    const comune = computed(() => store.getters.comune);
    const documentiModificabili = computed(
      () => store.getters.documentiModificabili
    );
    const stato = computed(() => store.getters.stato);

    const getSocieta = () => {
      const localKeys = {
        cod_affiliazione: cod_affiliazione.value,
        denominazione: denominazioneSocieta.value,
        comitato: comitato.value,
        rowsToSkip: rowsToSkipSocieta.value,
        fetchRows: fetchRowsSocieta.value,
        sortColumn: sortColumnSocieta.value,
        sortOrder: sortOrderSocieta.value,
        stagione: tutteStagioniSocieta.value ? 10000 : stagioneSocieta.value,
        stagioneCorrenteSocieta: stagioneCorrenteSocieta.value,
        disciplina: disciplina.value,
        affiliazioneDal: affiliazioneDal.value,
        affiliazioneAl: affiliazioneAl.value,
        regione: regione.value,
        provincia: provincia.value,
        comune: comune.value,
        documentiModificabili: documentiModificabili.value,
        stato: stato.value,
      };
      /*  */
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.SOCIETA_LIST_GET,
        itemName: "societa_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersSocieta");
      getSocieta();
    };
    getSocieta();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedsocieta_list")
    );

    const searchSocieta = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipSocieta");
      getSocieta();
    };
    watch(stagioneSocieta, () => searchSocieta());

    return {
      getSocieta,
      resetFilters,
      searchSocieta,
    };
  },
});
</script>
