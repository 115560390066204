<template>
  <Loading :active="isLoading" :is-full-page="fullPage" :z-index="1100" />
  <div>
    <div class="row mt-5 justify-content-between">
      <div class="col-3" v-if="isEnabled('fnSocietaAddAnagrafica')">
        <div>
          <router-link
            to="/societa/add-societa"
            class="btn btn-light text-gray-700 fs-6 text-start"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi anagrafica
            società
          </router-link>
        </div>
      </div>
      <div
        class="col-3"
        :class="isEnabled('fnSocietaAddAnagrafica') ? 'text-center' : ''"
        v-if="readOnly || isEnabled('fnSocietaEsportaLista')"
      >
        <div>
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-center"
            data-bs-toggle="modal"
            data-bs-target="#modal_esporta_societa"
          >
            <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
            società filtrata
          </button>
        </div>
      </div>
      <ModalEsportaSocieta />
      <div class="col-3 text-center">
        <div class="btn btn-light text-gray-700 fs-6 text-center">
          <input
            class="form-check-input me-2"
            type="checkbox"
            @click="setTutteStagioniSocieta"
            :checked="tutteStagioniSocieta"
          />visualizza tutte le stagioni
        </div>
      </div>
      <div class="col-3">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzate
          <b
            >{{ rowsToSkip + fetchRows - (fetchRows - 1) }} -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}</b
          >
          Società di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <!-- {{ recordSocieta }} -->
    <div v-if="status === 200 || !status">
      <div v-if="loaded && record != 0">
        <Datatable
          :table-header="tableHeader"
          :table-data="societa_list"
          :total="record"
          :current-page="currentPage"
          :rows-per-page="fetchRows"
          :sortLabel="sortColumn"
          :order="sortOrder"
          @sort="onSort"
          @items-per-page-change="onChangeFetchRow"
          @current-change="onPageChange"
        >
          <template v-slot:cell-cod_affiliazione="{ row: data }">
            <router-link
              :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id}`"
            >
              {{ data.cod_affiliazione }}</router-link
            >
          </template>
          <template v-slot:cell-anno="{ row: data }">
            <router-link
              :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id}`"
            >
              {{ data.anno }}</router-link
            >
          </template>
          <template v-slot:cell-denominazione="{ row: data }"
            ><div class="tab-long">
              <router-link
                :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id}`"
              >
                {{ data.denominazione }}</router-link
              >
            </div>
          </template>
          <template v-slot:cell-localita="{ row: data }">
            <router-link
              :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id}`"
            >
              {{ data.localita }}</router-link
            >
          </template>
          <template v-slot:cell-comitato="{ row: data }">
            <router-link
              :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id}`"
            >
              {{ data.comitato }}</router-link
            >
          </template>
          <template v-slot:cell-stato_nome="{ row: data }">
            <router-link
              :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id}`"
            >
              <h1
                class="badge"
                :class="
                  data.stato_nome === 'affiliazione completata'
                    ? 'badge-light-success'
                    : data.stato_nome === 'richiesta validata'
                    ? 'badge-light-warning'
                    : data.stato_nome === 'in approvazione'
                    ? 'badge-secondary'
                    : data.stato_nome === 'da pagare'
                    ? 'badge-light-primary'
                    : data.stato_nome === 'annullata'
                    ? 'badge-light-danger'
                    : 'badge-light'
                "
              >
                {{ data.stato_nome }}
              </h1></router-link
            >
          </template>
          <template v-slot:cell-options="{ row: data }">
            <div class="btn-group">
              <router-link
                :to="`/societa/dettaglio-societa/anagrafica-societa/${data.id}`"
                class="btn btn-sm dropdown p-1"
                ><i class="bi bi-eye text-dark fs-5"></i></router-link
              ><button
                v-if="!readOnly"
                class="btn btn-sm dropdown p-1"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
              </button>
              <ul
                class="dropdown-menu"
                style="min-width: 14rem"
                v-if="!readOnly"
              >
                <div
                  type="button"
                  @click="
                    viewSgatCredenziali(data.id);
                    denominazioneSocieta = data.denominazione;
                  "
                >
                  <li role="presentation" class="navi-item">
                    <div class="b-dropdown-text text-start py-2 px-4">
                      <div class="navi-link">
                        <i class="bi bi-shield-lock text-dark fs-5"></i>
                        <span class="text-dark ps-1 fs-7">
                          Gestisci credenziali SGAT</span
                        >
                      </div>
                    </div>
                  </li>
                </div>
                <div
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_add_affiliazione_tab"
                  @click="selectedSocieta = data"
                  v-if="data.puo_richiedere_affiliazione_secondaria"
                >
                  <li role="presentation" class="navi-item">
                    <div class="b-dropdown-text text-start py-2 px-4">
                      <div class="navi-link">
                        <i class="bi bi-plus text-dark fs-5"></i>
                        <span class="text-dark ps-1 fs-7">
                          Aggiungi affiliazione secondaria</span
                        >
                      </div>
                    </div>
                  </li>
                </div>
                <button
                  class="d-none"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_sgat"
                  id="openModalSgat"
                ></button>
              </ul>
            </div>
          </template>
        </Datatable>
      </div>
      <div class="mx-auto my-auto text-center" v-else-if="!loaded">
        <div class="spinner-border text-gray-600" role="status"></div>
        <span class="text-gray-600 ps-3 fs-2">Loading</span>
      </div>
      <div
        v-else-if="record === 0"
        class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
      >
        <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
        <span class="text-gray-700 ps-3 fs-2">
          Attenzione! Nessun risultato.
        </span>
        <span
          type="button"
          data-bs-toggle=""
          data-bs-target=""
          aria-expanded="false"
          aria-controls=""
          class="text-gray-600 pt-4"
          @click.prevent="resetFilters"
        >
          <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
        ></span>
        <!-- <span class="sr-only">Loading...</span> -->
      </div>
    </div>
    <div
      v-else
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="resetFilters"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <ModalCredenzialiSgat
      :societa="denominazioneSocieta"
      :name="name"
      :id_utente="id_utente"
      :errorMessage="errorMessage"
    />
    <ModalAddAffiliazione
      :affiliazioneSecondaria="true"
      :societa="selectedSocieta"
      @loadAffiliazioniSocieta="$emit('getSocieta')"
    />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import Datatable from "../../kt-datatable/KTDatatable.vue";
import RouterLink from "vue-router";
import ModalEsportaSocieta from "./ModalEsportaSocieta.vue";
import ModalAddAffiliazione from "@/components/components-fit/società/affiliazioni/ModalAddAffiliazioneTab.vue";

import isEnabled from "@/composables/isEnabled.js";
import ModalCredenzialiSgat from "./credenziali-sgat/ModalCredenzialiSgat.vue";
import { viewSgat } from "@/requests/sgatRequests";

import Loading from "vue3-loading-overlay";

export default {
  name: "SocietaTable",
  components: {
    Datatable,
    RouterLink,
    ModalEsportaSocieta,
    ModalCredenzialiSgat,
    Loading,
    ModalAddAffiliazione,
  },
  emits: ["getSocieta", "resetFilters"],
  setup(props, { emit }) {
    const store = useStore();

    const tableHeader = ref([
      {
        name: "Codice",
        key: "cod_affiliazione",
      },
      {
        name: "Stagione affiliazione",
        key: "anno",
      },
      {
        name: "Denominazione",
        key: "denominazione",
      },
      {
        name: "Indirizzo",
        key: "localita",
      },
      {
        name: "Comitato",
        key: "comitato",
      },
      {
        name: "Stato",
        key: "stato_nome",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    // chiamate - store ACTIONS

    const recordSocieta = computed(() =>
      store.getters.getStateFromName("recordSocieta")
    );

    // funzionalità - store ACTIONS / MUTATION
    function resetFilters() {
      // store.commit("resetSocieta");
      emit("resetFilters");
    }

    function onSort({ columnName, order }) {
      store.commit("setSortColumnSocieta", columnName);
      store.commit("setSortOrderSocieta", order);
      emit("getSocieta");
    }

    function onChangeFetchRow(num) {
      store.commit("setRowsToSkipSocieta");
      store.commit("setFetchRowsSocieta", num);
      emit("getSocieta");
    }

    function onPageChange(page) {
      store.commit("setPageSocieta", page);
      emit("getSocieta");
    }
    const setTutteStagioniSocieta = () => {
      store.commit("setTutteStagioniSocieta");
      emit("getSocieta");
    };

    const denominazioneSocieta = ref("");
    const name = ref(null);
    const id_utente = ref(null);
    const errorMessage = ref(null);
    const isLoading = ref(false);

    const viewSgatCredenziali = (id) => {
      name.value = null;
      id_utente.value = null;
      errorMessage.value = null;
      isLoading.value = true;
      viewSgat(id).then((res) => {
        isLoading.value = false;
        document.getElementById("openModalSgat").click();
        if (res.status == 200) {
          name.value = res.data.results[0].name;
          id_utente.value = res.data.results[0].id_utente;
        } else {
          errorMessage.value = res.data.message
            ? res.data.message
            : "Attenzione! Si è verificato un errore. Riprovare più tardi";
        }
      });
    };

    const selectedSocieta = ref([]);

    return {
      // campi e variabili pagina
      recordSocieta,
      tableHeader,
      // liste old style
      societa: computed(() => store.getters.societa),
      currentPage: computed(() => store.getters.currentPageSocieta),
      error: computed(() => store.getters.errorSocieta),
      loading: computed(() => store.getters.loadingSocieta),
      totalElements: computed(() => store.getters.totalElements),
      fetchRows: computed(() => store.getters.fetchRowsSocieta),
      rowsToSkip: computed(() => store.getters.rowsToSkipSocieta),
      sortColumn: computed(() => store.getters.sortColumnSocieta),
      sortOrder: computed(() => store.getters.sortOrderSocieta),
      // funzionalità
      resetFilters,
      onPageChange,
      onSort,
      onChangeFetchRow,
      setTutteStagioniSocieta,
      isEnabled,
      tutteStagioniSocieta: computed(() => store.getters.tutteStagioniSocieta),

      societa_list: computed(() =>
        store.getters.getStateFromName("resultssocieta_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedsocieta_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordsocieta_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statussocieta_list")
      ),
      readOnly: computed(() =>
        store.getters.getStateFromName("read_onlysocieta_list")
      ),
      name,
      id_utente,
      errorMessage,
      isLoading,
      denominazioneSocieta,
      viewSgatCredenziali,
      selectedSocieta,
    };
  },
};
</script>
